/**
 * Значения флагов:
 * bool - вкл/выкл соответственно
 * string - ключ localStorage, значение флага будет взято из localStorage по этому ключу
 * function - значение флага будет равно результату вызова этой функции
 *
 * По умолчанию значения флага распространяется для всех stage
 * настроить флаг для разных stage можно передав объект вида:
 * {
 *   [stage]: [flag]
 * }
 */

export const config = {
    alerts: true,
    approvals: true,
    businessUpgradeInfo: true,
    groupAccounts: true,
    mediaSection: {
        dev: true,
        production: true,
        qa: true,
        stage: true,
    },
    // miles* не удалять
    milesActivateStatus: true,
    milesBuy: true,
    milesCertificate: true,
    milesCharity: true,
    milesProlongation: true,
    milesRecovery: true,
    milesTransfer: true,
    milesUpgrade: true,
    militaryId: {
        production: 'militaryId_2502724782wn8cw5wcfm0mu4d1238098',
        qa: true,
        rest: true,
        stage: 'militaryId_2502724782wn8cw5wcfm0mu4d1238098',
    },
    notSendExpirePassword: true,
    // payments* не удалять
    paymentsApplePay: false,
    paymentsGooglePay: false,
    paymentsSavedCards: true,
    preferencesSubscriptions: {
        production: 'notSendExpirePassword_1b3cd3ad52a7708eabeeaa8d144a6120',
        qa: 'notSendExpirePassword_1b3cd3ad52a7708eabeeaa8d144a6120',
        rest: true,
        stage: 'notSendExpirePassword_1b3cd3ad52a7708eabeeaa8d144a6120',
    },
    // qa* добавляет возможности для тестирования, включаем только на qa
    qaShowNewOrder: {
        qa: 'showNewOrder_9b0cbb05f9c2654091ec692be0aeb56b',
    },
    qrcodes: false,
    setPassword: {
        production: 'setPassword_62cef76fa97dde6d2a0decfdcbba1354',
        qa: true,
        rest: true,
        stage: 'setPassword_62cef76fa97dde6d2a0decfdcbba1354',
    },
    // social не удалять
    socialApple: false,
    socialAuth: true,
    socialAuthFromReg: true,
    socialFacebook: false,
    socialGoogle: false,
    socialInstagram: false,
    socialOneTap: false,
    socialTinkoff: true,
    socialTinkoffFromLogin: true,
    socialTinkoffFromReg: true,
    socialVkontakte: true,
    socialYandex: true,
    subscriptions: true,
    transactionsExtended: true,
    travelerPets: true,
    tripsArchiveFilter: {
        production: false,
        qa: false,
        rest: true,
        stage: false,
    },
    tripsInfoNotification: false,
    market: {
        production: true,
        qa: true,
        dev: true,
        stage: true,
    },
};
