import { isAllow } from './allow';

export const RULE_SOCIAL_AUTH = isAllow('socialAuth');
export const RULE_SOCIAL_AUTH_FROM_REG = isAllow('socialAuthFromReg');
export const RULE_SOCIAL_APPLE = isAllow('socialApple');
export const RULE_SOCIAL_FACEBOOK = isAllow('socialFacebook');
export const RULE_SOCIAL_ONE_TAP = isAllow('socialOneTap');
export const RULE_SOCIAL_TINKOFF = isAllow('socialTinkoff');
export const RULE_SOCIAL_TINKOFF_FROM_LOGIN = isAllow('socialTinkoffFromLogin');
export const RULE_SOCIAL_TINKOFF_FROM_REG = isAllow('socialTinkoffFromReg');
export const RULE_SOCIAL_VKONTAKTE = isAllow('socialVkontakte');
export const RULE_SOCIAL_INSTAGRAM = isAllow('socialInstagram');
export const RULE_SOCIAL_YANDEX = isAllow('socialYandex');

export const RULE_PAYMENTS_APPLEPAY = isAllow('paymentsApplePay');
export const RULE_PAYMENTS_SAVEDCARDS = isAllow('paymentsSavedCards');

export const RULE_APPROVALS = isAllow('approvals');

export const RULE_PREFERENCES_SUBSCRIPTIONS = isAllow(
    'preferencesSubscriptions',
);

export const RULE_MILES_ACTIVATE_STATUS = isAllow('milesActivateStatus');
export const RULE_MILES_BUY = isAllow('milesBuy');
export const RULE_MILES_CERTIFICATE = isAllow('milesCertificate');
export const RULE_MILES_CHARITY = isAllow('milesCharity');
export const RULE_MILES_PROLONGATION = isAllow('milesProlongation');
export const RULE_MILES_RECOVERY = isAllow('milesRecovery');
export const RULE_MILES_TRANSFER = isAllow('milesTransfer');
export const RULE_MILES_UPGRADE = isAllow('milesUpgrade');

export const RULE_MILES_NEW_ORDER = isAllow('qaShowNewOrder');

export const RULE_TRANSACTIONS_EXTENDED = isAllow('transactionsExtended');

export const RULE_TRIPS_INFO_NOTIFICATION = isAllow('tripsInfoNotification');
export const RULE_TRIPS_ARCHIVE_FILTER = isAllow('tripsArchiveFilter');

export const RULE_SET_PASSWORD = isAllow('setPassword');
export const RULE_NOT_SEND_EXPIRE_PASSWORD = isAllow('notSendExpirePassword');

export const RULE_SUBSCRIPTIONS = isAllow('subscriptions');

export const RULE_QRCODES = isAllow('qrcodes');

export const RULE_ALERTS = isAllow('alerts');

export const RULE_GROUP_ACCOUNT = isAllow('groupAccounts');

export const RULE_TRAVELER_PETS = isAllow('travelerPets');

export const RULE_MILITARY_CARD = isAllow('militaryId');

export const RULE_BUSINESS_UPGRADE = isAllow('businessUpgradeInfo');

export const RULE_MEDIA_SECTION = isAllow('mediaSection');

export const RULE_MARKET = isAllow('market');

export default isAllow;
