import { formatMemberId } from 'src/shared/lib/formatMemberId';

import { ActionsCopy } from '@s7/icons';
import { Flex, Stack } from '@s7/layout';
import { AbstractFunction } from '@s7/type-utils';
import { BackgroundImage, TextButton, Typography } from '@s7/ui';

import { backgroundSx, textSx } from './NewPriorityCard.styles';

type Props = {
    firstName: string;
    lastName: string;
    memberId: string;
    level: string;
    handleCopy: AbstractFunction;
};

export const NewPriorityCard = (props: Props) => {
    const { firstName, lastName, memberId, level, handleCopy } = props;
    const formattedLevel = level.toLowerCase().replace(' ', '_');

    return (
        <BackgroundImage
            src={`static/status-cards/${formattedLevel}.png`}
            sx={backgroundSx}
        >
            <Stack spacing="7s">
                <Flex justify="right">
                    <TextButton
                        rightSide={
                            <ActionsCopy color="text.onColor" size={16} />
                        }
                        onClick={handleCopy}
                    >
                        <Typography sx={textSx}>
                            {formatMemberId(memberId)}
                        </Typography>
                    </TextButton>
                </Flex>
                <Stack spacing={0}>
                    <Typography sx={textSx}>{firstName}</Typography>
                    <Typography sx={textSx}>{lastName}</Typography>
                </Stack>
            </Stack>
        </BackgroundImage>
    );
};
