import { rem, SX } from '@s7/styles';

export const backgroundSx: SX = theme => ({
    position: 'relative',
    zIndex: 5,
    display: 'block',
    width: rem(302),
    height: rem(180),
    padding: theme.fn.space('5s'),
    borderRadius: theme.fn.borderRadius('2r'),
});

export const textSx: SX = theme => ({
    color: theme.palette.text.onColor,
    fontWeight: 500,
});
