import React from 'react';
import { COOKIES } from 'consts/cookies';
import {
    arrayOf,
    bool,
    func,
    node,
    number,
    objectOf,
    oneOfType,
    shape,
    string,
} from 'prop-types';
import {
    RULE_SOCIAL_APPLE,
    RULE_SOCIAL_FACEBOOK,
    RULE_SOCIAL_TINKOFF_FROM_LOGIN,
    RULE_SOCIAL_VKONTAKTE,
    RULE_SOCIAL_YANDEX,
} from 'utils/allowFeature';
import cookiesOptions from 'utils/cookiesOptions';
import { isDevelop, isQa, isRest } from 'utils/stages';
import { dictKeys, getTestIdGetter } from 'utils/testIds';

import LoginWidget from '@s7/login-widget';

import cx from './Login.sss';

const getTestId = getTestIdGetter(dictKeys.LOGIN);

const SOME_SOCIAL_RULE_ENABLED =
    RULE_SOCIAL_APPLE ||
    RULE_SOCIAL_FACEBOOK ||
    RULE_SOCIAL_TINKOFF_FROM_LOGIN ||
    RULE_SOCIAL_VKONTAKTE ||
    RULE_SOCIAL_YANDEX;

const Login = props => {
    const {
        cookiesDomain,
        headingColor,
        info,
        isB2B,
        isModal,
        isOpen,
        temporaryResource,
        isRecovery,
        language,
        login,
        loginBottomText,
        loginButtonText,
        loginHeaderText,
        loginHref,
        onClose,
        onLoginError,
        onLoginSuccess,
        onRecoverySuccess,
        openRegistrationInNewTab,
        recoveryHref,
        recoverySecret,
        recoveryStep,
        registrationHref,
        showSuccessRecoverPopupOnMount,
        socialApple,
        socialFacebook,
        socialButtonsVisible,
        socialLogin,
        socialLoginParameters,
        socialState,
        socialTinkoff,
        socialVk,
        socialYandex,
        stage: stageProp,
        title,
        withLoyalty,
        withProfile,
        oneSignalUserId,
    } = props;

    return (
        <div
            className={cx({ page: !isModal })}
            {...(!isModal ? { ...getTestId('page') } : {})}
        >
            <div className={cx('root')}>
                <LoginWidget
                    showSuccessRecoverPopupOnMount={
                        showSuccessRecoverPopupOnMount
                    }
                    socialTinkoff={
                        RULE_SOCIAL_TINKOFF_FROM_LOGIN && socialTinkoff
                    }
                    cookiesDomain={cookiesDomain}
                    headingColor={headingColor}
                    info={info}
                    isAllowedDataIds={isDevelop || isQa || isRest}
                    isB2B={isB2B}
                    isModal={isModal}
                    isOpen={isOpen}
                    isRecovery={isRecovery}
                    language={language}
                    login={login}
                    loginBottomText={loginBottomText}
                    loginButtonText={loginButtonText}
                    loginHeaderText={loginHeaderText}
                    loginHref={loginHref}
                    oneSignalUserId={oneSignalUserId}
                    openRegistrationInNewTab={openRegistrationInNewTab}
                    recoveryHref={recoveryHref}
                    recoverySecret={recoverySecret}
                    recoveryStep={recoveryStep}
                    registrationHref={registrationHref}
                    socialApple={RULE_SOCIAL_APPLE && socialApple}
                    socialButtonsVisible={socialButtonsVisible}
                    socialFacebook={RULE_SOCIAL_FACEBOOK && socialFacebook}
                    socialGoogle={false}
                    socialLogin={socialLogin && SOME_SOCIAL_RULE_ENABLED}
                    socialLoginParameters={socialLoginParameters}
                    socialOneTap={false}
                    socialState={socialState}
                    socialVk={RULE_SOCIAL_VKONTAKTE && socialVk}
                    socialYandex={RULE_SOCIAL_YANDEX && socialYandex}
                    ssdcpName={COOKIES.SSDCP}
                    ssdgeName={COOKIES.SSDGE}
                    ssdklName={COOKIES.SSDKL}
                    stage={stageProp}
                    temporaryResource={temporaryResource}
                    title={title}
                    titleSize={3}
                    withLoyalty={withLoyalty}
                    withProfile={withProfile}
                    onClose={onClose}
                    onLoginError={onLoginError}
                    onLoginSuccess={onLoginSuccess}
                    onRecoverySuccess={onRecoverySuccess}
                />
            </div>
        </div>
    );
};

Login.propTypes = {
    cookiesDomain: string,
    /**
    Возможные значения:
    [
      'N80',
      'N100',
      'N300',
      'N500',
      'N900',
      'W100',
      'R600',
      'R800',
      'R900',
      'G600',
      'G800',
      'G900',
      'B600',
      'B800',
      'B900',
      'O600',
      'O800',
      'O900',
      'T600',
      'T800',
      'T900',
      'inherit',
    ]
  */
    headingColor: string,
    info: node,
    isB2B: bool,
    isModal: bool,
    isOpen: bool,
    isRecovery: bool,
    /**
    Возможные значения: ['ru', 'en', 'it', 'de', 'es', 'zh']
  */
    language: string,
    locale: string,
    login: string,
    loginBottomText: node,
    loginButtonText: node,
    loginHeaderText: node,
    loginHref: oneOfType([func, string]),
    onClose: func,
    onLoginError: func,
    onLoginSuccess: func,
    onRecoverySuccess: func,
    openRegistrationInNewTab: bool,
    recoveryHref: oneOfType([func, string]),
    recoverySecret: string,
    /**
    Возможные значения: ['default', 'sended', 'confirmed']
    */
    recoveryStep: string,
    registrationHref: string,
    showSuccessRecoverPopupOnMount: bool,
    socialApple: bool,
    socialButtonsVisible: number,
    socialFacebook: bool,
    socialLogin: bool,
    socialLoginParameters: objectOf(oneOfType([objectOf(string), string])),
    socialState: shape({
        error: shape({
            code: string,
            details: arrayOf(
                shape({
                    code: string,
                    value: string,
                }),
            ),
            message: string,
        }),
        token: string,
        type: string,
    }),
    socialTinkoff: bool,
    socialVk: bool,
    socialYandex: bool,
    ssdcpName: string,
    ssdgeName: string,
    ssdklName: string,
    /**
    Возможные значения: ['production', 'qa', 'proxy']
  */
    stage: string,
    submitButtonText: node,
    temporaryResource: string,
    title: node,
    withLoyalty: bool,
    withProfile: bool,
};

Login.defaultProps = {
    cookiesDomain: cookiesOptions.domain || null,
    headingColor: 'N900',
    isModal: false,
    isRecovery: false,
    language: 'ru',
    loginHref: '/login',
    openRegistrationInNewTab: false,
    registrationHref: '/registration',
    socialApple: false,
    socialButtonsVisible: null,
    socialLogin: true,
    ssdcpName: COOKIES.SSDCP,
    ssdgeName: COOKIES.SSDGE,
    ssdklName: COOKIES.SSDKL,
    stage: 'proxy',
    withLoyalty: false,
    withProfile: false,
};

export default React.memo(Login);
