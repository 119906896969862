export const actions = {
    MyProfile: {
        contacts: {
            onConfirmEmailClick: 'Click_Edit_SendCode',
            onConfirmEmailSmsClick: 'Click_EmailConfirm',
            onConfirmEmailSmsSuccess: 'EmailConfirmCode_Success',
            onConfirmPhoneClick: 'Click_PhoneSendSMS',
            onConfirmPhoneSmsClick: 'Click_PhoneConfirm',
            onConfirmPhoneSmsSuccess: 'PhoneConfirm_Success',
            onResendEmailClick: 'Сlick_Edit_SendCode_N',
            onResendPhoneClick: 'Click_PhoneSendSMS_N',
        },
        documents: {
            onAddDocumentClick: 'Click_AddDocument',
            onAddDocumentSuccess: 'SaveDocument_Success',
            onAddVisaClick: 'Click_AddVisa',
            onAddVisaSuccess: 'SaveVisa_Success',
            onDocscanClick: 'Click_ScanDocument',
            onSaveDocumentClick: 'Click_SaveDocument',
            onSaveVisaClick: 'Click_SaveVisa',
            onVisaDocscanClick: 'Click_ScanVisa',
        },
        onMilesPageOpen: 'Miles_Open',
        onSaveToApplePayClick: 'Click_SaveToAPay',
        personalData: {
            onEditDataClick: 'Click_AddData',
            onSaveDataClick: 'Click_SaveData',
            onSaveDataSuccess: 'SaveData_Success',
        },
        profileHeader: {
            onMilesTabClick: 'Click_GoToMiles',
            onProfileTabClick: 'Click_GoToData',
            onTripsTabClick: 'Click_GoToTrips',
        },
        travellers: {
            onAddTravellerClick: 'Companions_AddCompanion',
            onRemoveTravellerSuccess: 'Companions_Delete',
            onSaveTravellerClick: 'Companions_SaveCompanion',
        },
    },
    'MyProfile Miles': {
        milesActions: {
            charity: {
                onConfirmClick: 'Charity_ClickConfirm',
                onExpand: 'Charity_Expand',
                onSuccess: 'Charity_ConfirmSuccess',
            },
            claimmiles: {
                onConfirmClick: 'Claim_ClickConfirm',
                onExpand: 'Claim_Expand',
                onResetClick: 'Claim_ClearInput',
                onSuccess: 'Claim_ConfirmSuccess',
            },
            expire: {
                onExpand: 'ExpireMiles_Expand',
            },
            gift: {
                onConfirmClick: 'Gift_ClickConfirm',
                onExpand: 'Gift_Expand',
                onSuccess: 'Gift_ConfirmSuccess',
                onTermsClick: 'Gift_ClickTerms',
            },
            onLearnMoreClick: 'Click_MoreInfo',
            onLoad: 'LoadTeaser',
            prolongation: {
                onExpand: 'Prolongation_Expand',
            },
            purchasemiles: {
                onConfirmClick: 'Purchase_ClickConfirm',
                onExpand: 'Purchase_Expand',
                onShowPrices: 'Purchase_ShowPrices',
                onSuccess: 'Purchase_ConfirmSuccess',
            },
            transfermiles: {
                onConfirmClick: 'TransferMiles_ClickConfirm',
                onExpand: 'TransferMiles_Expand',
                onShowPrices: 'TransferMiles_ShowPrices',
                onSuccess: 'TransferMiles_ConfirmSuccess',
            },
            upgrade: {
                onExpand: 'Upgrade_Expand',
            },
        },
        transactions: {
            onPeriodChange: 'Transactions_Period',
            onResetClick: 'Transactions_Clear',
            onShowMoreClick: 'Transactions_LoadMore',
            onTypeChange: 'Transactions_Flow',
        },
    },
    'MyProfile REG_FLOW': {
        onChildRegistrationClick: 'Click_GoToKid',
        onContinueClick: 'Click_Continue',
        onDocscanClick: 'Click_ScanDocument',
        onRegisterClick: 'step03_СlickConfirm',
        onRegisterSuccess: 'step04_RegSuccess',
        onResendSmsClick: 'Click_GetSMS_N',
        onSendSmsClick: 'step01_GetSMS',
        onSmsCorrect: 'step02_SMSCorrect',
    },
};

export default actions;
