import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { func, oneOf, string } from 'prop-types';
import { Dictionary } from 'shared';
import { NewPriorityCard } from 'src/entities/priority-card';
import { copyText } from 'utils';
import { normalizeNumber } from 'utils/format';

import { addNotification } from '../../store/modules/shared';

export const PriorityCard = React.memo(function PriorityCard(props) {
    const dispatch = useDispatch();
    const { memberId } = props;

    const showQRcodeNotification = useCallback(() => {
        dispatch(
            addNotification({
                delay: 3000,
                message: (
                    <Dictionary text="com.title.copy-number-success" html />
                ),
                type: 'success',
            }),
        );
    }, [dispatch]);

    const handleCopy = React.useCallback(() => {
        copyText(normalizeNumber(memberId), {
            onSuccess: showQRcodeNotification,
        });
    }, [memberId, showQRcodeNotification]);

    return <NewPriorityCard handleCopy={handleCopy} {...props} />;
});

PriorityCard.propTypes = {
    firstName: string.isRequired,
    lastName: string.isRequired,
    level: oneOf([
        'Classic',
        'ClassicJunior',
        'ClassicMaster',
        'ClassicExpert',
        'ClassicTop',
        'Silver',
        'Gold',
        'Platinum',
    ]),
    memberId: string.isRequired,
    onCopy: func,
};
